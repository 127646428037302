import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import {
    TeaserSection,
    BaseContentSection,
    findNamedBacgroundAsset,
    SimpleButton,
  } from '../components/section';
import { PageSections } from '../templates/page-sections';
import createTranslate from '../utils/translator-provider';

export default ({ data }) => {
    const translate = createTranslate(data?.page?.translations);

    const contentBackgroundAsset = findNamedBacgroundAsset("Background", data?.page?.sectionBackgrounds);

    return(
        <Layout
            title={data.page.title}
            description={data.page.seoDescription}
            path={'/404'}
            fixedBackground={contentBackgroundAsset?.asset}
        >
            <TeaserSection
                backgroundAssets={data?.page?.sectionBackgrounds}
                sectionName={'Teaser'}
                title={translate('errorPage.404.teaserSection.title')}
            />
            <BaseContentSection>
              <a href="/">
                <SimpleButton>Palaa etusivulle</SimpleButton>
              </a>
            </BaseContentSection>
        </Layout>
    )
}


export const query = graphql`
  query {
    page: contentfulPage(name: { eq: "404 page" }) {
      title
      seoDescription
      sectionBackgrounds {
        name
        asset {
          file {
            contentType
            url
          }
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        brightness
        useShadowForText
        objectPositionVertical
        objectPositionHorizontal
      }
      pageSections {
        title
        sectionParagraphs {
          ... on ContentfulCampaignContentParagraph {
            name
            columnsToTake
            content {
              json
            }
          }
          ... on ContentfulItemGroup {
            id
            header
            title
            items {
              ... on ContentfulPreviewContent {
            title
            predescription {

              ... on contentfulPreviewContentPredescriptionTextNode {
                    predescription
                  }
                }
                image  {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                      fluid(maxWidth: 800) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                      }
                }
                linkText
                link
              }

              ... on ContentfulPartner {
                    name
                    description
                    image {
                        file {
                            contentType
                            url
                        }
                        localFile {
                            publicURL
                            childImageSharp {
                            fluid(maxWidth: 3000) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                            }
                        }
                        fluid(maxWidth: 3000) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
          }
        }
      }
      translations {
        key
        value {
          value
        }
      }
    }
  }
`;
